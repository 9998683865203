import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/mitgliederversammlung/2020/01.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Mitgliederversammlung2020 = props => {
  return (
    <Layout>
      <SEO title="Mitgliederversammlung 2020" lang="de" />
      <Container>
        <h1 className="h3">
          Mitgliederversammlung des Schützenverein 1961 Gambach e.V.
        </h1>
        <hr className="featurette-divider" />
        <small>Aus der Butzbacher Zeitung</small>
        <h6>Schützenverein Gambach zieht Bilanz</h6>
        <p>
          Gambach (fs). Die Mitgliederversammlung des Schützenvereins 1961
          Gambach stand ganz im Zeichen eines ereignisreichen und aktiven Jahres
          mit Schießbetrieb, Meisterschaften und weiteren Aktivitäten. Der
          Vorsitzende Friedhelm Schwenz und sein Stellvertreter Rudolf Haas
          ehrten sechs Mitglieder für langjährige Mitgliedschaft. In seinem
          bebilderten Rückblick ließ Schwenz die verschiedenen Veranstaltungen
          des vergangenen Jahres noch einmal Revue passieren, wie die
          Durchführung der Bezirksmeisterschaften mit dem Luftgewehr, die
          Teilnahme am TSV-Volkswandern, bei dem die Teilnehmer den ersten Platz
          erwanderten. Das Zusammenwirken mit anderen Vereinen wie beim
          „Oster-Fez-Ferienspaß“ der Stadt Münzenberg sei erfolgreich gewesen
          und man wolle auch dieses Jahr wieder einen Schnupperkurs anbieten,
          und die Jugend einladen.
        </p>
        <p>
          Anlässlich des Sommerfest mit dem 24. Königsschießen ging Günter
          Seiffermann als Schützenkönig und Anna-Dorothea Becker als
          Schützenkönigin hervor. Spaß am Schießsport hatten auch die Teilnehmer
          des zum ´40. Mal durchgeführten Wanderpokalschießens, das vom
          Vorsitzenden als Erfolg bewertet wurde, der auch an der der Meldungen
          sichtbar wurde: 109 Teilnehmer in 20 Teams, darunter vier Damen-Teams.
          Beim traditionellen Nikolausschießen gab Britta Beppler-Alt den
          bestplatzierten Schuss ab. Zum Jahresende nahmen einige Aktive am
          Preiswürfeln in Münzenberg teil. Die gemeinnützige Arbeit und aktive
          Jugendarbeit wurden durch die Volksbank Butzbach und die Stadt
          Münzenberg im Rahmen der städtischen Vereinsförderung unterstützt. In
          seinem detaillierten Sportbericht erläuterte Harald Fink die guten
          Leistungen und erfolgreichen Platzierungen der vergangenen Saison.
          Hier waren gute Leistungen der drei Luftgewehr-Mannschaften und
          Einzelschützen bei den verschiedenen Meisterschaften zu verzeichnen.
          Die erste Luftgewehr-Mannschaft konnte einen ersten und die zweite
          Luftgewehr-Mannschaft den ersten Platz belegen. Weitere Meistertitel
          und Platzierungen in den verschiedenen Klassen wurden erzielt. Die
          Nachwuchsschützen unterstützen inzwischen aktiv die Mannschaften bei
          den Rundenwettkämpfen und haben sich als tragende Stützen etabliert.
          Ein neues Mitglied konnte im Schützenverein begrüßt werden.
        </p>
        <p>
          In seiner Übersicht stellte Kassenwart Lothar Döring die geordneten
          Finanzen und die solide Situation des Vereins dar. Eine vorbildliche
          Führung der Kassen- und Buchführungsgeschäfte wurde von den
          Kassenprüfern Anna-Dorothea Becker und Walter Beppler-Alt attestiert,
          verbunden mit dem Antrag auf Entlastung des Gesamtvorstandes, welchem
          die Versammlung einstimmig folgte. In der nachfolgenden Vorstandswahl,
          die durch den gewählten Versammlungsleiter Michael Mayer geleitet
          wurde, erhielt der gesamte Vorstand das Vertrauen der Mitglieder und
          wurde in seinen Ämtern bestätigt. Der neue/alte geschäftsführende
          Vorstand setzt sich zusammen aus: Friedhelm Schwenz (Vorsitzender),
          Rudolf Haas (stellv. Vorsitzender), Lothar Döring (Kassenwart) sowie
          Willi Beppler-Alt (Schriftführer). Der erweiterte Vorstand wurde
          ebenfalls bestätigt und setzt sich zusammen aus: Harald Fink
          (Schießwart), Heiko Lippert (Stand- und Gerätewart), Rigo Schubach,
          Hendrik Ruf und Lars Wächter (Beisitzer) sowie Katharina Beppler-Alt
          und Fabienne Hofmann (Vergnügungsausschuss). Unter dem Applaus der
          Mitglieder dankte Schwenz der „guten Fee des Schützenhauses“, Ulrike
          Beppler-Alt, für ihre geleistete Arbeit der vergangenen Jahre mit
          einem Blumengebinde. Unter Punkt Verschiedenes wurden noch Themen
          bezüglich des Vereinsjahres behandelt und die weiteren Termine für das
          laufende Jahr bekannt gegeben. Die Mitgliederversammlung klang mit
          einem kleinen Imbiss am Abend aus.
        </p>
        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image01} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Das Bild zeigt die Jubilare, die für ihre langjährige
              Vereinszugehörigkeit geehrt wurden, v.l.: Rudolf Haas (2. Vors.),
              Klaus Buß, Vera Seiffermann und Dieter Becker (je 40 Jahre),
              Patrick Haas (25 Jahre) sowie Friedhelm Schwenz (1. Vors). Auf dem
              Bild fehlen: Gerhard Buß und Günter Brucker (40 Jahre). (Foto: hr)
            </small>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Mitgliederversammlung2020
